import { post } from '@/http'
// 新建发票
export function changedInvoice(data) {
    return new Promise((resolve, reject) => {
        post('/Invoice/ChangedInvoice', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

// 我的发票列表
export function getUserInvoice(data) {
    return new Promise((resolve, reject) => {
        post('/Invoice/GetUserInvoice', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
// 我的优惠券列表
export function getDisList(data) {
    return new Promise((resolve, reject) => {
        post('/Discount/GetList', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

// 提交订单
export function creatOrder(data) {
    return new Promise((resolve, reject) => {
        post('/Order/CreatOrder', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

// 获取用户基本信息
export function getUserInfo(data) {
    return new Promise((resolve, reject) => {
        post('/Users/GetUserInfo', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

//  删除收货地址
export function removeAddress(data) {
    return new Promise((resolve, reject) => {
        post('/Address/RemoveAddress', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

// 新建/编辑收货地址
export function changedAddress(data) {
    return new Promise((resolve, reject) => {
        post('/Address/ChangedAddress', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export function searchCompanyName(data) {
    return new Promise((resolve, reject) => {
        post('/Invoice/SearchCompanyName', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export function getCompanyDetailInfo(data) {
    return new Promise((resolve, reject) => {
        post('/Invoice/GetCompanyDetailInfo', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export function getNoLineCart(data) {
    return new Promise((resolve, reject) => {
        post('/ShoppingCart/getNoLineCart', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
