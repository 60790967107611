import { post } from '@/http'
// 我的发票列表
export function getUserInvoice(data) {
    return new Promise((resolve, reject) => {
        post('/Invoice/GetUserInvoice', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

// 删除发票
export function removeInvoice(data) {
    return new Promise((resolve, reject) => {
        post('/Invoice/RemoveInvoice', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export function searchCompanyName(data) {
    return new Promise((resolve, reject) => {
        post('/Invoice/SearchCompanyName', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export function getCompanyDetailInfo(data) {
    return new Promise((resolve, reject) => {
        post('/Invoice/GetCompanyDetailInfo', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
