<template>
	<div class="settlement">
		<div class="settlement-main">
			<div class="breadcrumb">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item><span style="cursor: pointer" @click="$router.push({ path: '/' })">首页</span></el-breadcrumb-item>
					<el-breadcrumb-item><span>我的订单</span></el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="title">确认收货地址</div>
			<div class="addr">
				<div class="addr-list" v-if="myCity">
					<div class="border"></div>
					<div>
						<el-row class="addr-list-top">
							<el-col :span="7">{{ myCity.RealName }}</el-col>
							<el-col :span="14">{{ myCity.Phone }}</el-col>
							<el-col :span="3" class="edit"><span @click.stop="editAddr(myCity)">修改</span></el-col>
						</el-row>
						<div class="addr-info">{{ myCity.Province }}{{ myCity.City }}{{ myCity.District }} {{ myCity.Address }}</div>
					</div>
				</div>
				<div class="add-addr" @click="addAddr">
					<i class="iconfont iconadd"></i>
					<span>新增收货地址</span>
				</div>
			</div>
			<div class="city-main" v-if="myCityList && myCityList.length > 1">
				<div class="city-span" v-if="!cityShow" @click="loadingCity">
					<div>更多地址</div>
					<div class="gd"><i :class="['iconfont', 'iconshouqi']"></i></div>
				</div>
				<div class="city-span" v-if="cityShow" @click="loadingCity">
					<div>收起地址</div>
					<div class=""><i :class="['iconfont', 'iconshouqi']"></i></div>
				</div>
			</div>
			<div v-if="cityShow">
				<div class="list-city" v-for="(item, index) in myCityList" :key="index" style="cursor: pointer;" @click="changeCity(item)">
					<table border="0" cellspacing="0" cellpadding="0">
						<tr>
							<td width="150">{{ item.RealName }}</td>
							<td width="150">{{ item.Province }}{{ item.City }}{{ item.District }}</td>
							<td width="150">{{ item.Address }}</td>
							<td width="150">{{ item.Postcode }}</td>
							<td width="150">{{ item.Phone }}</td>
							<td width="150">
								<span class="handle" @click.stop="editAddr(item)">修改</span>
								<span class="m">|</span>
								<span class="handle" @click.stop="delAddr(item)">删除</span>
							</td>
							<td width="150">
								<span v-if="item.IsDefault || index == 0" class="active">默认地址</span>
								<span v-else @click.stop="editAddr1(item)">设为默认</span>
							</td>
						</tr>
					</table>
				</div>
			</div>
			<div class="invoice">
				<div class="invoice-top">
					<div>发票信息</div>
					<div class="edit" v-if="invoice" @click="handleEditInvoice">修改</div>
					<div class="edit" v-else @click="handleEditInvoice1">新增</div>
				</div>
				<div class="invoice-bottom" v-if="invoice">
					<div style="margin-left: 60px">
						<span v-if="invoice.InvoiceType == 2 || invoice.InvoiceType == 3">增值税普通发票</span>
						<span v-if="invoice.InvoiceType == 1">增值税专用发票</span>
						<span v-if="invoice.InvoiceType == 3" style="margin-left: 10px">个人</span>
						<span style="margin-left: 10px" v-if="invoice.InvoiceType == 2 || invoice.InvoiceType == 1">单位</span>
					</div>
					<div>
						<el-form label-width="120px">
							<el-form-item label="发票抬头">{{ invoice.InvoiceTitle }}</el-form-item>
							<template v-if="invoice.InvoiceType == 2 || invoice.InvoiceType == 1">
								<el-form-item label="纳税人识别号">{{ invoice.DutyParagraph }}</el-form-item>
								<el-form-item label="单位地址">{{ invoice.Address }}</el-form-item>
								<el-form-item label="单位电话">{{ invoice.Phone }}</el-form-item>
								<el-form-item label="开户银行">{{ invoice.BankName }}</el-form-item>
								<el-form-item label="银行账号">{{ invoice.BankNumber }}</el-form-item>
							</template>
						</el-form>
					</div>
				</div>
				<div
					class="city-span"
					style="margin:0 70px 20px 70px;

                    "
					v-if="!Showinvoice && invoiceLists.length > 1"
					@click="loadingInvoice"
				>
					<div>更多发票</div>
					<div class="gd"><i :class="['iconfont', 'iconshouqi']"></i></div>
				</div>
				<div
					class="city-span"
					style="margin:0 70px 20px 70px;

                    "
					v-if="Showinvoice && invoiceLists.length > 1"
					@click="loadingInvoice"
				>
					<div>收起发票</div>
					<div class=""><i :class="['iconfont', 'iconshouqi']"></i></div>
				</div>
				<div v-if="Showinvoice">
					<div class="list-city" v-for="(item, index) in invoiceLists" :key="index" style="cursor: pointer;" @click="changeCitys(item)">
						<table border="0" cellspacing="0" cellpadding="0">
							<tr>
								<td width="150">
									{{ item.RealName }}
									<span v-if="item.InvoiceType == 2 || item.InvoiceType == 1">单位</span>
									<span v-if="item.InvoiceType == 3">个人</span>
								</td>
								<td width="350">{{ item.InvoiceTitle }}</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
			<div class="title">确认订单信息</div>
			<div class="list">
				<table border="0" cellspacing="0" cellpadding="0">
					<tr class="list-title">
						<th width="410">商品信息</th>
						<th width="350">单价(元)</th>
						<th width="350">数量</th>
						<th width="350">实付款(元)</th>
					</tr>
				</table>

				<!--订单号-->
				<table
					style="border-collapse:separate;
                    border-spacing:0 10px;"
					border="0"
					cellspacing="0"
					cellpadding="0"
					v-for="(item, index) in cartList"
					:key="index"
				>
					<tr class="order-mian" style="margin:10px">
						<td width="350" class="card-content">
							<i class="iconfont iconshuishuai-01"></i>
							<span v-if="item.Tax == 0.13">实物类商品</span>
							<span v-else>服务类商品</span>
						</td>
						<td width="350" v-for="j in 3" :key="j"></td>
					</tr>
					<tr class="order-mian-list" v-for="(j, i) in item.List" :key="i">
						<td style="display: flex" class="border">
							<div><img :src="pic + j.Picture" alt="" /></div>
							<div class="order-mian-list-text">
								<div class="proname">{{ j.ProName }}</div>
								<div class="sku">{{ j.BarCode }}</div>
							</div>
						</td>
						<td class="border">
							<div class="original">￥{{ j.InTaxPrice }}</div>
							<div class="present">￥{{ j.Price }}</div>
						</td>
						<td class="border border-right">
							<div>X {{ j.PCount }}</div>
						</td>

						<td class="border-right" :rowspan="item.List.length" v-if="i == 0">
							<div class="subtotal">{{ item.number }}</div>
							<div class="tax">
								<span v-if="item.Tax == 0.13">税率：13%</span>
								<span v-else>税率：6%</span>
							</div>
						</td>
					</tr>
				</table>
			</div>
			<div class="discount" v-if="disList.length > 0">
				<div class="discount-top">
					使用优惠
					<span style="color: #f39800;font-weight: normal; font-size: 12px;margin-left:20px">同类优惠券一次只能使用一张</span>
				</div>
				<div class="discount-bottom">
					<div v-for="(item, index) in disList" :key="index">
						<div :class="['discount-list', { 'discount-active': item.active }]" v-if="item.State == 1" @click="changeDisList(item, index)">
							<div class="price-main">
								<div class="price">
									<div class="sss">
										<span v-if="item.Type == 1">小类优惠券</span>
										<span v-if="item.Type == 2">全场通用</span>
									</div>
									<div>￥{{ item.Money }}</div>
								</div>
								<div class="price-info">
									<span v-if="item.MinPrice > 0">满{{ item.MinPrice }}减{{ item.Money }}</span>
									<span v-else>通用{{ item.Money }}</span>
								</div>
								<div class="date">有效期至{{ item.CreatTime }}</div>
							</div>
							<div class="border"></div>
						</div>
					</div>
				</div>
			</div>

			<div class="discount" style="margin-top:20px">
				<div class="discount-top">备注</div>
				<div class="discount-bottom"><el-input type="textarea" :rows="2" placeholder="请输入备注" v-model="ruleForm.Remark"></el-input></div>
			</div>
			<div class="total" v-if="invoice">
				<div class="total-left">
					<div class="total-left-list">
						<span>配送方式：</span>
						<span class="button">快递寄送</span>
					</div>
					<div class="total-left-list">
						<span>发票信息：</span>
						<span class="button" v-if="invoice.InvoiceType == 1">增值税专用发票</span>
						<span class="button" v-if="invoice.InvoiceType == 2 || invoice.InvoiceType == 3">增值税普通发票</span>
						<span class="info" v-if="invoice.InvoiceType == 2">单位 商品明细</span>
						<span class="info" v-if="invoice.InvoiceType == 3">个人 商品明细</span>
					</div>
					<div class="total-left-list-addr">
						<div>收货地址：</div>
						<div class="addrs" v-if="myCity">{{ myCity.RealName }} {{ myCity.Phone }} {{ myCity.Province }}{{ myCity.City }}{{ myCity.District }} {{ myCity.Address }}</div>
					</div>
				</div>
				<div class="total-right">
					<div class="total-right-item">
						<div class="total-right-item-lable">
							<span class="number">{{ number }}</span>
							件商品，总商品金额：
						</div>
						<div class="ct">￥{{ price }}</div>
					</div>
					<!--                    <div class='total-right-item'>-->
					<!--                        <div class='total-right-item-lable'>税费：</div>-->
					<!--                        <div class='ct'>￥{{ taxPrice }}</div>-->
					<!--                    </div>-->
					<div class="total-right-item">
						<div class="total-right-item-lable">运费：</div>
						<div class="number">免运费</div>
					</div>
					<div class="total-right-item">
						<div class="total-right-item-lable">优惠券：</div>
						<div class="number">-￥ {{ disValue }}</div>
					</div>
					<div class="total-right-item">
						<div class="total-right-item-lable">实付款：</div>
						<div class="pay">￥{{ (price - disValue).toFixed(2) }}</div>
					</div>
				</div>
			</div>
			<div class="submit-main"><div class="submit" @click="infodialogVisible = true">提交订单</div></div>
			<el-dialog title="发票信息" class="invoice-dialog" :visible.sync="dialogVisible" width="500px">
				<div>
					<el-radio-group v-model="form.radio" size="small">
						<el-radio v-for="(item, index) in invoiceList" :key="index" :label="item.label" border :disabled="item.disabled">{{ item.title }}</el-radio>
					</el-radio-group>
				</div>
				<div class="invoice-dialog-main">
					<div>
						<el-form ref="ruleForm1" :model="form" :rules="rules1" label-width="120px">
							<el-form-item label="发票抬头">
								<el-radio-group v-model="form.InvoiceType" size="small" @change="changeRise">
									<el-radio :label="2" border>单位</el-radio>
									<el-radio :label="3" border v-if="form.radio == 2">个人</el-radio>
								</el-radio-group>
							</el-form-item>
							<!--个人-->
							<el-form-item label="个人名称" prop="InvoiceTitle" v-if="form.InvoiceType == 3">
								<el-input style="width: 300px" size="small" v-model="form.InvoiceTitle"></el-input>
							</el-form-item>
							<!--公司-->
							<div v-if="form.InvoiceType == 2 || form.InvoiceType == 1">
								<el-form-item label="单位名称" prop="InvoiceTitle">
									<el-autocomplete
										class="inline-input"
										:disabled="disabled"
										size="small"
										style="width: 300px"
										v-model="form.InvoiceTitle"
										:fetch-suggestions="querySearch"
										placeholder="请输入内容"
										:trigger-on-focus="false"
										@select="handleSelect"
									></el-autocomplete>
								</el-form-item>
								<el-form-item label="纳税人识别号" prop="DutyParagraph">
									<el-input style="width: 300px" v-model="form.DutyParagraph" :disabled="disabled" size="small" placeholder="请输入纳税人识别号"></el-input>
								</el-form-item>
								<el-form-item label="单位地址" prop="Address">
									<el-input style="width: 300px" v-model="form.Address" size="small" placeholder="请输入单位地址"></el-input>
								</el-form-item>
								<el-form-item label="单位电话" prop="Phone"><el-input style="width: 300px" v-model="form.Phone" size="small" placeholder="请输入单位电话"></el-input></el-form-item>
								<el-form-item label="开户银行" prop="BankName">
									<el-input style="width: 300px" v-model="form.BankName" size="small" placeholder="请输入开户银行"></el-input>
								</el-form-item>
								<el-form-item label="银行账号" prop="BankNumber">
									<el-input style="width: 300px" v-model="form.BankNumber" size="small" placeholder="请输入银行账号"></el-input>
								</el-form-item>
							</div>
							<el-form-item label="发票内容">
								<el-radio-group v-model="radio2" size="small"><el-radio :label="1" border>商品明细</el-radio></el-radio-group>
							</el-form-item>
							<el-form-item label="">
								<el-button class="ruleForm" size="mini" @click="submitForm('ruleForm1')">保存</el-button>
								<el-button size="mini" @click="dialogVisible = false">取消</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</el-dialog>
			<el-dialog title="收货地址" :visible.sync="addrdialogVisible" width="30%">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="所在地区" prop="city"><el-cascader size="small " :props="optionProps" v-model="ruleForm.city" :options="cityList"></el-cascader></el-form-item>
					<el-form-item label="具体地址" prop="Address"><el-input type="textarea" :rows="2" placeholder="请输入具体地址" v-model="ruleForm.Address"></el-input></el-form-item>
					<el-form-item label="收件人" prop="RealName"><el-input style="width:150px" placeholder="请输入收件人" v-model="ruleForm.RealName"></el-input></el-form-item>
					<el-form-item label="联系电话" prop="Phone"><el-input style="width:150px" placeholder="请输入联系电话" v-model="ruleForm.Phone"></el-input></el-form-item>
					<el-form-item label="邮编" prop="Postcode"><el-input style="width:150px" placeholder="请输入邮编" v-model="ruleForm.Postcode"></el-input></el-form-item>
					<el-form-item label="">
						<el-checkbox-group v-model="ruleForm.IsDefault"><el-checkbox label="设置为默认收货地址" name="type"></el-checkbox></el-checkbox-group>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button @click="addrdialogVisible = false">取 消</el-button>
					<el-button @click="submitForm1('ruleForm')">保存</el-button>
				</span>
			</el-dialog>
			<el-dialog title="收货地址" :visible.sync="addrdialogVisible" width="30%">
				<el-form :model="ruleForm1" :rules="rules2" ref="ruleForm2" label-width="100px" class="demo-ruleForm">
					<el-form-item label="所在地区" prop="city"><el-cascader size="small " :props="optionProps" v-model="ruleForm1.city" :options="cityList"></el-cascader></el-form-item>
					<el-form-item label="具体地址" prop="Address"><el-input type="textarea" :rows="2" placeholder="请输入具体地址" v-model="ruleForm1.Address"></el-input></el-form-item>
					<el-form-item label="收件人" prop="RealName"><el-input style="width:150px" placeholder="请输入收件人" v-model="ruleForm1.RealName"></el-input></el-form-item>
					<el-form-item label="联系电话" prop="Phone"><el-input style="width:150px" placeholder="请输入联系电话" v-model="ruleForm1.Phone"></el-input></el-form-item>
					<el-form-item label="邮编" prop="Postcode"><el-input style="width:150px" placeholder="请输入邮编" v-model="ruleForm1.Postcode"></el-input></el-form-item>
					<el-form-item label="">
						<el-checkbox-group v-model="ruleForm1.IsDefault"><el-checkbox label="设置为默认收货地址" name="type"></el-checkbox></el-checkbox-group>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button @click="addrdialogVisible = false">取 消</el-button>
					<el-button @click="submitForm2('ruleForm2')">保存</el-button>
				</span>
			</el-dialog>

			<el-dialog title="收货和发票信息确认" class="info-confirm-dialog" :visible.sync="infodialogVisible" width="30%">
				<div class="info-confirm-wrap">
					<div class="title">收货信息</div>
					<template v-if="myCity && myCity.ID">
						<div class="cell">
							<div class="label">收货人</div>
							<div class="value">{{ myCity.RealName }}</div>
						</div>

						<div class="cell">
							<div class="label">收货电话</div>
							<div class="value">{{ myCity.Phone }}</div>
						</div>

						<div class="cell">
							<div class="label">收货地址</div>
							<div class="value">{{ myCity.Address }}</div>
						</div>
					</template>
					<template v-else>
						<div class="empty">
							<span>请填写收货地址</span>
							<el-button @click="addAddr()" type="danger" size="small">添加</el-button>
						</div>
					</template>
					<div class="line"></div>

					<div class="title">发票信息</div>
					<template v-if="invoice && invoice.ID">
						<div class="cell column" style="margin-top: -10px;">
							<div class="label">发票类型</div>
							<div class="value">
								<span class="tag">{{ invoice.InvoiceType == 1 ? '增值税专用发票' : '增值税普通发票' }}</span>
							</div>
						</div>
						<div class="cell column">
							<div class="label">发票抬头</div>
							<div class="value"><span class="tag">单位</span></div>
						</div>

						<div class="cell">
							<div class="label">单位名称</div>
							<div class="value">{{ invoice.InvoiceTitle }}</div>
						</div>

						<div class="cell">
							<div class="label">纳税人识别号</div>
							<div class="value">{{ invoice.DutyParagraph }}</div>
						</div>
						<div class="cell">
							<div class="label">单位地址</div>
							<div class="value">{{ invoice.Address }}</div>
						</div>
						<div class="cell">
							<div class="label">单位电话</div>
							<div class="value">{{ invoice.Phone }}</div>
						</div>
						<div class="cell">
							<div class="label">开户银行</div>
							<div class="value">{{ invoice.BankName }}</div>
						</div>
						<div class="cell">
							<div class="label">银行账号</div>
							<div class="value">{{ invoice.BankNumber }}</div>
						</div>
						<div class="cell" style="padding-bottom: 40px;">
							<div class="label">发票内容</div>
							<div class="value">
								<span class="tag">商品明细</span>
								发票内容将显示详细商品名称与价格
							</div>
						</div>
					</template>
					<template v-else>
						<div class="empty">
							<span>请填写发票信息</span>
							<el-button @click="handleEditInvoice1()" type="danger" size="small">添加</el-button>
						</div>
					</template>
				</div>
				<div slot="footer" class="dialog-footer">
					<el-button @click="infodialogVisible = false" size="small">取 消</el-button>
					<el-button @click="submit()" type="danger" size="small">确 认</el-button>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import cityLevelThree from '../addr/cityLevelThree.js';
import { changedAddress, getUserAddressList } from '../addr/service';
import { changedInvoice, creatOrder, getNoLineCart, getUserInvoice, searchCompanyName, getCompanyDetailInfo, removeAddress, getUserInfo } from './service';
import { getDisList } from './service';
import { removeInvoice } from '../invoice/service';

export default {
	data() {
		return {
			infodialogVisible: false,
			rules2: {
				city: [
					{
						required: true,
						message: '请选择详细地区',
						trigger: 'change'
					}
				],
				RealName: [
					{
						required: true,
						message: '请选择详细地区',
						trigger: 'change'
					}
				],
				Phone: [
					{
						required: true,
						message: '请输入手机号',
						trigger: ['blur', 'change']
					},
					{
						pattern: /^1[3|5|7|8|9]\d{9}$/,
						message: '请输入正确的手机号'
					}
				],

				Address: [
					{
						required: true,
						message: '请选择具体地址',
						trigger: 'change'
					}
				]
			},
			rules1: {
				InvoiceTitle: [
					{
						required: true,
						message: '请输入内容',
						trigger: 'change'
					}
				],
				DutyParagraph: [
					{
						required: true,
						message: '请输入内容',
						trigger: 'change'
					}
				],
				Address: [
					{
						required: true,
						message: '请输入内容',
						trigger: 'change'
					}
				],
				Phone: [
					{
						required: true,
						message: '请输入内容',
						trigger: 'change'
					}
				],
				BankName: [
					{
						required: true,
						message: '请输入内容',
						trigger: 'change'
					}
				],
				BankNumber: [
					{
						required: true,
						message: '请输入内容',
						trigger: 'change'
					}
				]
			},
			addrdialogVisible: false,
			Showinvoice: false,
			optionProps: {
				value: 'label',
				label: 'label',
				children: 'children'
			},
			cityList: [],
			number: 0,
			cityShow: false,
			taxPrice: 0,
			InvoiceTitle: '',
			userInfo: {},
			disList: [],
			disActiveList: [],
			disValue: 0,
			price: 0,
			pic: FILE_URL,
			myCity: null,
			ruleForm1: {
				city: [],
				IsDefault: []
			},
			myCityList: [],
			disabled: false,
			invoiceLists: [],
			invoiceList: [
				{
					title: '增值税专用发票',
					label: 1,
					disabled: false
				},
				{
					title: '增值税普通发票',
					label: 2,
					disabled: false
				}
			],
			dialogVisible: false,
			form: {
				radio: 1,
				InvoiceType: 2
			},
			ruleForm: {
				city: [],
				IsDefault: []
			},
			rules: {
				city: [
					{
						required: true,
						message: '请选择详细地区',
						trigger: 'change'
					}
				],
				RealName: [
					{
						required: true,
						message: '请选择详细地区',
						trigger: 'change'
					}
				],
				Phone: [
					{
						required: true,
						message: '请输入手机号',
						trigger: ['blur', 'change']
					},
					{
						pattern: /^1[3|5|7|8|9]\d{9}$/,
						message: '请输入正确的手机号'
					}
				],
				Address: [
					{
						required: true,
						message: '请选择具体地址',
						trigger: 'change'
					}
				]
			},
			invoice: null,
			radio2: 1,
			cartList: []
		};
	},
	watch: {
		isLogin: {
			handler(n) {
				this.init();
				this.cityList = cityLevelThree.list;
			},
			// deep: true,
			immediate: true
		}
	},
	mounted() {},
	methods: {
		querySearch(queryString, cb) {
			if (queryString != '') {
				searchCompanyName({ name: queryString }).then(res => {
					for (const key in res.data) {
						res.data[key].value = res.data[key].name;
					}
					cb(res.data);
				});
			}
			// var restaurants = this.restaurants
			// var results = queryString
			//     ? restaurants.filter(this.createFilter(queryString))
			//     : restaurants
			// // 调用 callback 返回建议列表的数据
			// cb(results)
		},
		handleSelect(item) {
			getCompanyDetailInfo({ ID: item.id }).then(res => {
				this.form = {
					...this.form,
					DutyParagraph: res.data.taxNumber,
					Address: res.data.regLocation,
					Phone: res.data.phoneNumber
				};
			});
			this.disabled = true;
		},
		submitForm2(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					let data = {};
					data.Address = this.ruleForm1.Address;
					data.Phone = this.ruleForm1.Phone;
					data.RealName = this.ruleForm1.RealName;
					data.Postcode = this.ruleForm1.Postcode;
					data.ID = this.ruleForm1.ID ? this.ruleForm1.ID : 0;
					data.Province = this.ruleForm1.city[0];
					data.City = this.ruleForm1.city[1];
					data.District = this.ruleForm1.city[2];
					if (this.ruleForm1.IsDefault.length > 0) {
						data.IsDefault = true;
					} else {
						data.IsDefault = false;
					}
					changedAddress(data).then(() => {
						this.$message.success('操作成功');
						this.init();
						this.addrdialogVisible = false;
					});
				} else {
					return false;
				}
			});
		},
		submitForm1(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					let data = {};
					data.Address = this.ruleForm.Address;
					data.Phone = this.ruleForm.Phone;
					data.RealName = this.ruleForm.RealName;
					data.Postcode = this.ruleForm.Postcode;
					data.ID = this.ruleForm.ID ? this.ruleForm.ID : 0;
					data.Province = this.ruleForm.city[0];
					data.IsDefault = true;
					data.City = this.ruleForm.city[1];
					data.District = this.ruleForm.city[2];
					changedAddress(data).then(() => {
						this.$message.success('操作成功');
						this.init();
						this.addrdialogVisible = false;
					});
				} else {
					return false;
				}
			});
		},
		editAddr1(data) {
			// console.log(data);
			let obj = {
				ID: data.ID,
				Address: data.Address,
				Phone: data.Phone,
				RealName: data.RealName,
				Postcode: data.Postcode,
				city: [data.Province, data.City, data.District],
				Province: data.Province,
				IsDefault: true,
				City: data.City,
				District: data.District
			};
			changedAddress(obj).then(() => {
				location.reload();
			});
		},
		// 提交发票
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					if (this.form.radio == 1) {
						this.form.InvoiceType = 1;
					}
					this.form.IsDefault = false;
					this.dialogVisible = false;
					changedInvoice(this.form).then(() => {
						this.$message.success('操作成功');
						this.form = {
							radio: 2,
							InvoiceType: 2
						};
						location.reload();
					});
				} else {
					return false;
				}
			});
		},
		changeDisList(data, index) {
			if (this.disList[index].active) {
				this.disValue = 0;
				this.disList[index].active = false;
				this.disList.splice(0, 0);
				for (const key in this.disList) {
					if (this.disList[key].active) {
						this.disValue += this.disList[key].Money;
					}
				}
				for (const key in this.disActiveList) {
					if (this.disActiveList[key].ID == data.ID) {
						delete this.disActiveList[key];
					}
				}

				return;
			}

			// // 循环已经选择的所有优惠卷
			for (const disListKey in this.disActiveList) {
				if (this.disActiveList[disListKey].Type == data.Type) {
					this.$message.error('请先取消选中优惠卷，再选其他。');
					return;
				}
			}

			this.disActiveList.push(data);
			this.disList[index].active = true;
			this.disValue = 0;
			this.disList.splice(0, 0);
			for (const key in this.disList) {
				if (this.disList[key].active) {
					this.disValue += this.disList[key].Money;
				}
			}
		},
		init() {
			this.price = 0;
			this.taxPrice = 0;
			// 请求地址
			getUserAddressList().then(res => {
				if (res.data[0]) {
					this.myCity = res.data[0];
					this.myCityList = res.data;
				}
			});
			let cartList = new Map();
			let data = this.$route.query.data;
			data = data.split(',');
			let parameter = '';
			for (const dataKey in data) {
				let arr = data[dataKey].split('|');
				parameter += arr[0] + ',';
				cartList.set(arr[0], arr[1]);
				this.number++;
			}
			parameter = parameter.substr(0, parameter.length - 1);
			// 获取用户信息
			let IsVip = false;
			getUserInfo().then(res => {
				if (res.data.IsVip == 1) {
					IsVip = true;
					this.userInfo = res.data;
				} else {
					IsVip = false;
				}
			});
			getNoLineCart({
				IDs: parameter
			}).then(res => {
				for (let i in res.data) {
					res.data[i].number = 0;
					let p = 0;
					let p1 = 0;
					for (let j in res.data[i].List) {
						res.data[i].List[j].Price = IsVip ? res.data[i].List[j].PLUSPrice : res.data[i].List[j].Price;
						for (let a in data) {
							let arr = data[a].split('|');
							if (res.data[i].List[j].ID == arr[0]) {
								res.data[i].List[j].PCount = arr[1];
							}
						}
						p += parseFloat(res.data[i].List[j].Price) * parseFloat(res.data[i].List[j].PCount);
						p1 += res.data[i].List[j].TaxPrice * res.data[i].List[j].PCount;
					}
					res.data[i].number = p.toFixed(2);
					res.data[i].TaxPrice = p1.toFixed(2);
					this.price += parseFloat(res.data[i].number);
					this.taxPrice += (res.data[i].number / (1 + parseFloat(res.data[i].Tax))) * parseFloat(res.data[i].Tax);
				}
				// console.log(this.price);
				this.cartList = res.data;
				this.price = this.price.toFixed(2);
				this.taxPrice = this.taxPrice.toFixed(2);

				let good = '';
				for (let item in res.data) {
					for (let j in res.data[item].List) {
						good += res.data[item].List[j].ID + '|' + res.data[item].List[j].PCount + ',';
					}
				}
				good = good.substr(0, good.length - 1);

				getDisList({
					Price: this.price,
					PageIndex: 1,
					State: 1,
					PageSize: 100,
					GoodsInfo: good
				}).then(res => {
					this.disList = res.data;

					for (const key in this.disList) {
						this.disList[key].active = false;
					}
				});
			});
			// 获取发票地址
			getUserInvoice().then(res => {
				if (res.data[0]) {
					this.invoiceLists = res.data;
					this.invoice = res.data[0];
				}
			});
			// 获取优惠卷列表
		},
		// 删除收货地址
		delAddr(data) {
			this.$confirm('你确定要执行吗, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				removeAddress({
					ID: data.ID
				}).then(() => {
					this.$message.success('删除成功');
					this.init();
				});
			});
		},
		oks() {
			let data = {};
			if (this.myCity) {
				data.GoodsInfo = this.$route.query.data;
				data.AID = this.myCity.ID;
				data.EvIDs = '';
				data.OrderType = 2;
				if (this.invoice) {
					data.InvID = this.invoice.ID;
				}
				for (const disActiveListKey in this.disActiveList) {
					data.EvIDs += this.disActiveList[disActiveListKey].ID + ',';
				}
				if (data.EvIDs != '') {
					data.EvIDs = data.EvIDs.substr(0, data.EvIDs.length - 1);
				} else {
					delete data.EvIDs;
				}
			} else {
				this.$message.error('请选择收货地址');
				return;
			}
			if (this.$route.query.u) {
				data.RecommendUID = this.$route.query.u;
			}
			data.Remark = this.ruleForm.Remark;

			creatOrder(data)
				.then(res => {
					console.log(res);
					if (res.code == 0) {
						this.$message.error(res.msg);
					} else {
						if (res.data.OrderID) {
							this.$router.push({
								path: `/payment?orderID=${res.data.OrderID}`
							});
						} else {
							this.$message.error(res.msg);
						}
					}
				})
				.catch(err => {
					console.log(err);
				});
		},
		// 提交订单
		submit() {
			if (!this.myCity || !this.myCity.ID) {
				this.$alert('请填写收货地址');
				return false;
			}
			this.infodialogVisible = false;
			if (this.disValue > 0) {
				this.$confirm('您选择使用优惠券，提交后将无法修改，您确认吗？  ', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.oks();
				});
			} else {
				this.oks();
			}
		},
		// 新增收货地址跳转
		addAddr(type) {
			this.infodialogVisible = false;
			let data = {};
			if (type) {
				data = type;
			} else {
				data = this.ruleForm1;
			}
			this.addrdialogVisible = true;
			this.ruleForm1 = {
				ID: data.ID,
				Address: data.Address,
				Phone: data.Phone,
				RealName: data.RealName,
				IsDefault: data.IsDefault ? ['设置为默认收货地址'] : [],
				Postcode: data.Postcode,
				city: data.Province ? [data.Province, data.City, data.District] : []
			};
			// console.log(this.ruleForm1);
		},
		changeAddr(data) {
			let parmas = {
				ID: data.ID,
				Address: data.Address,
				Phone: data.Phone,
				RealName: data.RealName,
				Postcode: data.Postcode,
				city: [data.Province, data.City, data.District]
			};
			changedAddress(parmas).then(() => {
				this.$message.success('操作成功');
				location.reload();
			});
		},
		changeCity(item) {
			this.cityShow = false;
			this.myCity = item;
		},
		changeCitys(item) {
			this.Showinvoice = false;
			this.invoice = item;
		},
		handleEditInvoice1() {
			this.infodialogVisible = false;
			this.form = {
				radio: 1,
				InvoiceTitle: '',
				InvoiceType: 2
			};
			this.dialogVisible = true;
			this.disabled = false;
		},
		handleEditInvoice() {
			this.radio2 = 1;
			this.disabled = true;
			if (this.invoice) {
				if (this.invoice.InvoiceType == 1) {
					// 专用发票
					this.form = {
						...this.invoice,
						radio: 1,
						InvoiceType: 2
					};
				} else if (this.invoice.InvoiceType == 2) {
					// 专用发票
					this.form = {
						...this.invoice,
						radio: 2,
						InvoiceType: 2
					};
				} else if (this.invoice.InvoiceType == 3) {
					// 专用发票
					this.form = {
						...this.invoice,
						radio: 2,
						InvoiceType: 3
					};
				}
			}
			this.dialogVisible = true;
		},
		editAddr(data) {
			this.addrdialogVisible = true;
			this.ruleForm1 = {
				ID: data.ID,
				Address: data.Address,
				Phone: data.Phone,
				RealName: data.RealName,
				IsDefault: data.IsDefault,
				Postcode: data.Postcode,
				city: [data.Province, data.City, data.District]
			};
			// console.log(this.ruleForm1);
		},
		loadingCity() {
			this.cityShow = !this.cityShow;
		},
		loadingInvoice() {
			this.Showinvoice = !this.Showinvoice;
		},

		changeRise(value) {
			// 单位点击
			if (value == 2) {
				this.form.InvoiceTitle = this.InvoiceTitle;
				this.invoiceList[0].disabled = false;
				if (this.invoice.InvoiceType == 3) {
					this.disabled = false;
				}
			} else {
				this.InvoiceTitle = this.form.InvoiceTitle;
				this.form.InvoiceTitle = '';
				this.invoiceList[0].disabled = true;
			}
		}
	}
};
</script>

<style lang="less" scoped>
.info-confirm-dialog {
	display: flex;
	align-items: center;
	justify-content: center;
	::v-deep .el-dialog {
		width: 600px !important;
		height: 90vh;
		margin: 0 !important;
		.el-dialog__footer {
			padding: 0 !important;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 50px;
			.dialog-footer {
				display: flex;
				width: 200px;
				justify-content: space-around;
				.el-button {
					border-radius: 0;
					width: 80px;
				}
				.el-button--default {
					color: #51a1b3;
					background-color: #e3f5f7;
					border: solid 1px #e3f5f7;
				}
			}
		}
		.el-dialog__body {
			padding: 10px;
			background-color: #f1f1f1;
			height: calc(100% - 125px);
			overflow: auto;
			.info-confirm-wrap {
				background-color: #fff;
				height: auto;
				min-height: 100%;
				.empty {
					width: 100%;
					height: 70px;
					text-align: center;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: center;
					.el-button {
						width: 80px;
						border-radius: 0;
						margin-top: 15px;
					}
				}
				.line {
					height: 8px;
					width: 100%;
					background-image: linear-gradient(to right, #a4d866, #0898bb);
					margin: 20px 0;
				}
				.tag {
					padding: 6px 15px;
					font-size: 14px;
					color: #51a1b3;
					background-color: #e3f5f7;
					border-radius: 50px;
					border: solid 1px #51a1b3;
				}
				.title {
					margin-top: 0;
					font-size: 18px;
					padding: 10px 20px;
				}
				.cell {
					width: calc(100% - 40px);
					margin-left: 20px;
					padding: 15px 0;
					display: flex;
					border-bottom: solid 1px #f1f1f1;
					.label {
						width: 100px;
						color: #303030;
					}
					.value {
						color: #909090;
					}
				}
				.column {
					flex-direction: column;
					justify-content: space-around;
					.label,
					.value {
						height: 40px;
						line-height: 40px;
					}
					.value {
						margin-top: 10px;
					}
				}
			}
		}
	}
}
.proname {
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
}

.list-city {
	margin: 15px 0;
	border: 1px solid #f4f4f4;
	padding: 20px 0;
	font-size: 13px;
	color: #333333;

	td {
		text-align: center;
	}

	.handle {
		cursor: pointer;
	}

	.m {
		margin: 0 5px;
	}

	.active {
		background: rgba(27, 152, 170, 0.2);
		padding: 2px 20px;
		color: #1b98aa;
	}
}

.sku {
	width: fit-content;
	padding: 2px 10px;
	background: #f5f5f5;
	border: 1px solid #dedede;
	border-radius: 15px;
	color: #333333;
	font-size: 12px;
	margin: 10px 0px;
}

.card-content {
	padding: 0 20px;
	box-sizing: border-box;
	font-family: 'ct';
	line-height: 40px;
	height: 40px;
	background: #f9f9f9;
	display: flex;

	i {
		color: #1b98aa;
	}
}

.ruleForm {
	background: #dc2310;
	color: #ffffff;
	border: none;
}

.submit-main {
	display: flex;
	justify-content: flex-end;
}

.list {
	margin-top: 10px;

	.list-title {
		background: #eeeeee;
		height: 40px;
		text-align: center;
	}

	.order-mian {
		width: 1300px;
		height: 30px;
		line-height: 30px;
		font-size: 12px;
		margin: 10px 0;
		background: #f9f9f9;
		padding-left: 10px;
		box-sizing: border-box;
	}

	.order-mian-list {
		text-align: center;
		font-size: 13px;
		margin: 10px 0;
	}

	.order-mian-list-text {
		text-align: left;
		margin: 10px;
	}

	.subtotal {
		font-family: 'ct';
		color: #1b98aa;
		font-size: 17px;
	}

	.button {
		width: fit-content;
		padding: 2px 10px;
		margin: 10px auto;
		background: rgba(27, 152, 170, 0);
		border: 1px solid #d9d9d9;
		color: #999999;
	}

	img {
		width: 100px;
		height: 100px;
	}

	.border {
		padding-bottom: 10px;
		border-bottom: 1px solid #f4f4f4;
	}

	.border-left {
		border-left: 1px solid #f4f4f4;
	}

	.border-right {
		border-right: 1px solid #f4f4f4;
		border-bottom: 1px solid #f4f4f4;
	}

	.order-details {
		margin-top: 30px;
	}

	.paidui {
		color: #999999;

		span {
			margin-left: 5px;
		}
	}

	.handle {
		width: fit-content;
		margin: 10px auto;
		padding: 5px 15px;
		color: #ffffff;
		background: #dc2310;
	}
}

tr {
	margin: 10px;
}

.submit {
	background: #dc2310;
	color: #fff;
	padding: 20px 30px;
	margin: 30px;
	cursor: pointer;
}

.total {
	background: #f5f5f5;
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	padding: 40px 30px;
	box-sizing: border-box;

	.total-right-item {
		display: flex;
		margin: 20px 0;

		.total-right-item-lable {
			width: 250px;
			text-align: right;
			padding-right: 20px;
			color: #666666;
			margin-right: 40px;
		}

		.pay {
			font-family: 'ct';
			color: #dc2310;
			font-size: 22px;
		}
	}

	.total-left-list {
		margin: 30px 0;
		width: 600px;
	}

	.total-right-label {
		background: red;
		width: 300px;
	}

	.total-right {
		.number {
			font-family: 'ct';
			color: #dc2310;
			margin: 0 10px;
		}
	}

	.ct {
		font-family: 'ct';
	}

	.total-left-list-addr {
		display: flex;
	}

	.button {
		padding: 2px 10px;
		color: #666666;
		border: 1px solid #bfbfbf;
		margin-left: 20px;
	}

	.info {
		margin-left: 10px;
	}

	.addrs {
		width: 500px;
		margin-left: 20px;
	}
}

.discount-active {
	position: relative;
}

.discount-active:after {
	position: absolute;
	top: 8px;
	left: 5px;
	content: '';
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: #1b98aa;
}

.city-main {
	margin: 20px 0;
}

.city-span {
	color: #0097ba;
	cursor: pointer;
	display: flex;

	div {
		margin-right: 5px;
	}

	i {
		margin-left: 10px;
		font-size: 13px;
	}

	.gd {
		transform: rotate(180deg);
		-ms-transform: rotate(180deg); /* IE 9 */
		-moz-transform: rotate(180deg); /* Firefox */
		-webkit-transform: rotate(180deg); /* Safari 和 Chrome */
		-o-transform: rotate(180deg); /* Opera */
	}
}

.invoice {
	margin-top: 20px;
	box-sizing: border-box;
	border: 1px solid #f4f4f4;

	.invoice-top {
		font-family: 'ct';
		background: #f9f9f9;
		height: 40px;
		line-height: 40px;
		padding: 0 20px;
		display: flex;
		justify-content: space-between;

		.edit {
			color: #1b98aa;
			font-family: '微软雅黑';
			cursor: pointer;
		}
	}

	.invoice-bottom {
		padding: 20px;
		box-sizing: border-box;

		div {
			font-family: 'ct';
			margin: 5px;
		}
	}
}

.discount {
	box-sizing: border-box;
	border: 1px solid #f4f4f4;

	.discount-top {
		font-family: 'ct';
		background: #f9f9f9;
		height: 40px;
		line-height: 40px;
		padding: 0 20px;
	}

	.discount-bottom {
		padding: 20px;
		box-sizing: border-box;
		display: flex;
		flex-wrap: wrap;
	}

	.discount-list {
		margin: 10px;
		width: 278px;
		background: #ffffff;
		box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.15);
		cursor: pointer;

		.price-main {
			padding: 10px 10px 10px 20px;

			.date {
				color: #999999;
				margin: 5px 0;
				font-size: 12px;
			}

			.price-info {
				width: 260px;
				overflow: hidden;
				white-space: nowrap;
				margin-top: 10px;
				text-overflow: ellipsis;
			}
		}

		.border {
			height: 4px;
			background: linear-gradient(50deg, #60b689, #00b5c2, #0897b4);
		}

		.price {
			font-size: 22px;
			font-family: 'ct';
			color: #0097ba;
			display: flex;
			justify-content: space-between;
			border-bottom: 1px dashed #dcdcdc;
			padding-bottom: 10px;

			.sss {
				font-size: 16px;
			}
		}
	}
}

.card-list {
	display: flex;
	padding: 10px;

	.card-list-right {
		text-align: center;
		font-size: 12px;
		color: #999999;
		margin-top: 30px;

		.price {
			font-size: 16px;
			font-family: 'ct';
			color: #1b98aa;
		}
	}

	.card-list-left {
		padding-bottom: 20px;
		border-bottom: 1px solid #f4f4f4;
		border-right: 1px solid #f4f4f4;
	}

	.original {
		text-decoration: line-through;
		color: #999999;
		margin-top: 20px;
	}

	.number {
		margin-top: 30px;
		font-family: 'ct';
	}

	.present {
	}

	img {
		width: 100px;
		height: 100px;
		border: 1px solid #e5e5e5;
	}

	.sku {
		width: fit-content;
		padding: 2px 10px;
		background: #f5f5f5;
		border: 1px solid #dedede;
		border-radius: 15px;
		color: #333333;
		font-size: 12px;
		margin: 10px 0px;
	}
}

.original {
	text-decoration: line-through;
	color: #999999;
}

.tax {
	color: #999999;
}

.present {
	margin-top: 5px;
}

.settlement-main {
	width: 1240px;
	margin: 20px auto;

	.sign {
		color: #0097ba;
	}

	.title {
		margin-top: 30px;
		color: #333333;
		font-family: 'ct';
	}

	.addr {
		display: flex;
		flex-wrap: wrap;
	}

	.add-addr {
		width: 300px;
		background: #f9f9f9;
		margin-top: 15px;
		cursor: pointer;
		height: 100px;
		line-height: 100px;
		text-align: center;
		color: #333333;
		font-size: 16px;

		i {
			font-size: 19px;
			font-weight: 600;
		}
	}

	.addr-list {
		width: 300px;
		height: 100px;
		background: #f9f9f9;
		margin-top: 15px;
		cursor: pointer;
		margin-right: 10px;

		.addr-list-top {
			height: 40px;
			line-height: 40px;
			box-sizing: border-box;
			margin: 0 15px;
			font-size: 13px;
			border-bottom: 1px solid #e5e5e5;

			.edit {
				color: rgba(0, 181, 194, 0.75);
				cursor: pointer;
			}
		}

		.border {
			height: 2px;
			background: linear-gradient(50deg, #60b689, #00b5c2, #0897b4);
		}
	}

	.addr-info {
		font-size: 13px;
		text-overflow: -o-ellipsis-lastline;
		margin: 15px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	.w-50 {
		width: 50px;
	}

	.w-100 {
		width: 100px;
	}

	.w-150 {
		width: 150px;
	}

	.w-200 {
		width: 200px;
	}

	.w-400 {
		width: 400px;
	}

	.card-content {
		box-sizing: border-box;
		font-family: 'ct';
		line-height: 40px;
		height: 40px;
		background: #f9f9f9;
		display: flex;

		i {
			color: #1b98aa;
		}

		span {
			margin-left: 10px;
		}
	}
}
</style>

<style scoped>
.invoice-dialog >>> .el-dialog__header {
	background: #e5e5e5;
	padding: 10px;
	font-size: 14px;
}

.invoice-dialog-main {
	margin-top: 20px;
}

>>> .el-input__inner,
>>> .el-radio--small.is-bordered {
	border-radius: 0;
}

>>> .el-radio__input {
	display: none;
}

>>> .el-radio__input.is-checked {
	position: relative;
}

>>> .el-radio.is-bordered.is-checked {
	border-color: #dc2310;
}

>>> .el-radio__input.is-checked + .el-radio__label {
	color: black;
}

>>> .el-radio__label {
	padding: 0 10px;
}

.invoice-dialog >>> .is-checked::after {
	content: '';
	display: block;
	height: 18px;
	width: 18px;
	background-image: url('../../assets/invoice.png');
	background-size: 100%;
	position: absolute;
	right: 0;
	bottom: 0;
}
</style>
